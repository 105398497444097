import React from "react"
import SEO from "../components/SEO"
import Layout from "../components/Layout/Layout"
import "typeface-lato"

const PrivacyPage = () => {
  return (
    <>
      <SEO title="Datenschutzerklärung" />

      <Layout>
        <div className="privacy-wrapper">
          <h1>Datenschutzerklärung</h1>
          <h2>Datenschutzerklärung</h2>
          <p>
            Wir nehmen den Schutz Ihrer personenbezogenen Daten bei der
            Erhebung, Verarbeitung und Nutzung anlässlich Ihres Besuchs auf
            unserer Homepage sehr ernst. Folgende Daten werden während Ihres
            Besuchs auf unserer Homepage erfasst und auf folgende Weise genutzt:
          </p>
          <h2>Erhebung und Verarbeitung von Daten</h2>
          <p>
            Jeder Zugriff auf unsere Homepage und jeder Abruf einer auf der
            Homepage hinterlegten Datei wird protokolliert. Die Speicherung
            dient internen systembezogenen und statistischen Zwecken.
            Protokolliert werden: Name der abgerufenen Datei, Datum und Uhrzeit
            des Abrufs, übertragene Datenmenge, Meldung über erfolgreichen
            Abruf, Webbrowser und anfragende Domain. Des Weiteren werden die
            IP-Adressen der anfragenden Rechner protokolliert. Weitergehende
            personenbezogene Daten werden nur erfasst, wenn Sie diese Angaben
            freiwillig, etwa im Rahmen einer Anfrage oder Registrierung machen.
          </p>
          <p>
            Zur Auswertung der Besuche auf unseren Seiten verwenden wir Google
            Analytics oder ein vergleichbares Analysetool. Durch die Benutzung
            der Seiten erklären Sie sich hiermit einverstanden.
          </p>
          <h2>Nutzung und Weitergabe personenbezogener Daten</h2>
          <p>
            Soweit Sie uns personenbezogene Daten zur Verfügung gestellt haben,
            verwenden wir diese nur zur Beantwortung Ihrer Anfragen, zur
            Abwicklung mit Ihnen geschlossener Verträge und für die technische
            Administration. Ihre personenbezogenen Daten werden an Dritte nur
            weitergegeben oder sonst übermittelt, wenn dies zum Zwecke der
            Vertragsabwicklung erforderlich ist oder Sie zuvor eingewilligt
            haben. Sie haben das Recht, eine erteilte Einwilligung mit Wirkung
            für die Zukunft jederzeit zu widerrufen. Die Löschung der
            gespeicherten personenbezogenen Daten erfolgt, wenn Sie Ihre
            Einwilligung zur Speicherung widerrufen, wenn ihre Kenntnis zur
            Erfüllung des mit der Speicherung verfolgten Zwecks nicht mehr
            erforderlich ist oder wenn ihre Speicherung aus sonstigen
            gesetzlichen Gründen unzulässig ist.
          </p>
          <h2>Auskunftsrecht</h2>
          <p>
            Wir werden Sie gern auf Ihre schriftliche Anfrage hin über die zu
            Ihrer Person gespeicherten Daten informieren.
          </p>
          <h2>Sicherheitshinweis</h2>
          <p>
            Bei der Kommunikation per E-Mail kann die vollständige
            Datensicherheit von uns nicht gewährleistet werden, so dass wir
            Ihnen bei vertraulichen Informationen den Postweg empfehlen.
          </p>
          <h2>Rechtswirksamkeit</h2>
          <p>
            Sofern Teile oder einzelne Formulierungen dieses Textes der
            geltenden Rechtslage nicht, nicht mehr oder nicht vollständig
            entsprechen sollten, bleiben die übrigen Teile des Dokumentes in
            ihrem Inhalt und ihrer Gültigkeit davon unberührt.
          </p>
          <h2>Plugins und eingebettete Funktionen sowie Inhalte</h2>
          <p>
            Wir binden in unser Onlineangebot Funktions- und Inhaltselemente
            ein, die von den Servern ihrer jeweiligen Anbieter (nachfolgend
            bezeichnet als "Drittanbieter”) bezogen werden. Dabei kann es sich
            zum Beispiel um Grafiken, Videos oder Social-Media-Schaltflächen
            sowie Beiträge handeln (nachfolgend einheitlich bezeichnet als
            "Inhalte”).
          </p>
          <p>
            Die Einbindung setzt immer voraus, dass die Drittanbieter dieser
            Inhalte die IP-Adresse der Nutzer verarbeiten, da sie ohne die
            IP-Adresse die Inhalte nicht an deren Browser senden könnten. Die
            IP-Adresse ist damit für die Darstellung dieser Inhalte oder
            Funktionen erforderlich. Wir bemühen uns, nur solche Inhalte zu
            verwenden, deren jeweilige Anbieter die IP-Adresse lediglich zur
            Auslieferung der Inhalte verwenden. Drittanbieter können ferner
            sogenannte Pixel-Tags (unsichtbare Grafiken, auch als "Web Beacons"
            bezeichnet) für statistische oder Marketingzwecke verwenden. Durch
            die "Pixel-Tags" können Informationen, wie der Besucherverkehr auf
            den Seiten dieser Webseite, ausgewertet werden. Die pseudonymen
            Informationen können ferner in Cookies auf dem Gerät der Nutzer
            gespeichert werden und unter anderem technische Informationen zum
            Browser und zum Betriebssystem, zu verweisenden Webseiten, zur
            Besuchszeit sowie weitere Angaben zur Nutzung unseres
            Onlineangebotes enthalten als auch mit solchen Informationen aus
            anderen Quellen verbunden werden.
          </p>
          <p>
            Hinweise zu Rechtsgrundlagen: Sofern wir die Nutzer um deren
            Einwilligung in den Einsatz der Drittanbieter bitten, ist die
            Rechtsgrundlage der Verarbeitung von Daten die Einwilligung.
            Ansonsten werden die Daten der Nutzer auf Grundlage unserer
            berechtigten Interessen (d.h. Interesse an effizienten,
            wirtschaftlichen und empfängerfreundlichen Leistungen) verarbeitet.
            In diesem Zusammenhang möchten wir Sie auch auf die Informationen
            zur Verwendung von Cookies in dieser Datenschutzerklärung hinweisen.
          </p>
          <p>
            <ul>
              <li>
                <b>Verarbeitete Datenarten:</b> Nutzungsdaten (z.B. besuchte
                Webseiten, Interesse an Inhalten, Zugriffszeiten),
                Meta-/Kommunikationsdaten (z.B. Geräte-Informationen,
                IP-Adressen).
              </li>
              <li>
                <b>Betroffene Personen:</b> Nutzer (z.B. Webseitenbesucher,
                Nutzer von Onlinediensten).
              </li>
              <li>
                <b>Zwecke der Verarbeitung:</b> Bereitstellung unseres
                Onlineangebotes und Nutzerfreundlichkeit, Vertragliche
                Leistungen und Service.
              </li>
              <li>
                <b>Rechtsgrundlagen:</b> Berechtigte Interessen (Art. 6 Abs. 1
                S. 1 lit. f. DSGVO).
              </li>
            </ul>
          </p>
          <h2>Eingesetzte Dienste und Diensteanbieter:</h2>
          <p>
            ReCaptcha: Wir binden die Funktion "ReCaptcha" zur Erkennung von
            Bots, z.B. bei Eingaben in Onlineformularen, ein. Die
            Verhaltensangaben der Nutzer (z.B. Mausbewegungen oder Abfragen)
            werden ausgewertet, um Menschen von Bots unterscheiden zu können.
            Dienstanbieter: Google Ireland Limited, Gordon House, Barrow Street,
            Dublin 4, Irland, Mutterunternehmen: Google LLC, 1600 Amphitheatre
            Parkway, Mountain View, CA 94043, USA;
            Website:https://www.google.com/recaptcha/;
            Datenschutzerklärung:https://policies.google.com/privacy; Privacy
            Shield (Gewährleistung Datenschutzniveau bei Verarbeitung von Daten
            in den USA):{" "}
            <a
              href="https://www.privacyshield.gov/participant?id=a2zt0000000TRkEAAW&status=Active;"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.privacyshield.gov/participant?id=a2zt0000000TRkEAAW&status=Active;
            </a>
            Widerspruchsmöglichkeit (Opt-Out): Opt-Out-Plugin:{" "}
            <a
              href="http://tools.google.com/dlpage/gaoptout?hl=de"
              target="_blank"
              rel="noopener noreferrer"
            >
              http://tools.google.com/dlpage/gaoptout?hl=de
            </a>
            , Einstellungen für die Darstellung von Werbeeinblendungen:{" "}
            <a
              href="https://adssettings.google.com/authenticated"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://adssettings.google.com/authenticated
            </a>
            .
          </p>
          <p>
            Erstellt mit kostenlosem{" "}
            <a
              href="https://datenschutz-generator.de/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Datenschutz-Generator.de von Dr. Thomas Schwenke
            </a>
          </p>
        </div>
      </Layout>
    </>
  )
}

export default PrivacyPage
